export const EnglishOneOfStation = {
  stations: "Stations",
  station_over_view: "Station Over View",
  to_table: "To Tables",
  the_pump_meter: "The Pump Meter of ",
  start_date: "From Date",
  end_date: "To Date",
  totalizer: "Totalizer",
  more_nozzle_meter: "More Nozzle Meter",
  less_nozzle_meter: "Less Nozzle Meter",
  today_table: "Today Table",
  total_sales: "Total Sales",
  today_vehicle: "Today Vehicle",
  total_vehicle: "Total Vehicle",
  seven_day: "(7-Day)",
  the_fuel_in_of_kyawsan: "The Fuel in of",
  search: "SEARCH",
  the_fuel_balance_: "Fuel Balance of",
  total_record: "Total Records",
};