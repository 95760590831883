export const MyanmarStockBalance = {
    main_title: "စတော့လက်ကျန်စာရင်း",
    start_date: "စတင်ရက်",
    end_date: "ပြီးဆုံးရက်",
    tank_no: "ဆီကန် နံပါတ်",
    station: "စတေရှင်",
    search: "ရှာဖွေသည်",
    no: "နံပါတ်",
    tank: "ဆီကန်",
    opening: "အဖွင့်",
    receive: "ဆီလက်ခံ",
    issue: "အရောင်း",
    adjust: "ထိန်းညှိ",
    balance: "လက်ကျန်",
    total_issue:"စုစုပေါင်း အရောင်း",
    today_tank: "ယနေ့ဆီကန်",
    yesterday_tank: "ယမန်နေ့",
    today_gl: "ယနေ့ အရ/အရှုံး",
    total_gl: "စုစုပေါင်း အရ/အရှုံး",
    toExcel: "Excel သို့ပြောင်းရန်",
    toPrint: "Print ထုတ်ရန်",
    total_record: "စုစုပေါင်း မှတ်တမ်း",
    
}