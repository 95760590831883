export const EnglishFuelTypeTotalTabel = {
    main_title:"Fuel Type Total",
    no: 'no',
    fuel_type: 'Fuel Type',
    liter: 'Liter',
    price: 'Price',
    amount: 'Amount',
    remark: 'Remark',
    total_all:'Total All',
};
