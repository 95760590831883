export const EnglishDailySaleSummaryByStation = {
  main_title: "Daily Sale Summary by Station Report",
  start_date: "From Date",
  end_date: "To Date",
  station: "Station",
  search: "SEARCH",
  station_name: "Station Name",
  license_no: "License No",
  address: "Address",
  date_time: "Date/Time",
  liter: "li",
  total_price: "Total Price",
  kyat: "Kyats",
  nozzle_no: "Nozzle No",
  fuel_type: "Fuel Type",
  price_per_liter: "Price Per Liter",
  total_sale_liter: "Total Sale Liter",
  toExcel: "To Excel",
  toPrint: "To PDF",
};