export const MyanmarDailySaleCategoriesReport = {
     mainTitle: "နေ့စဉ်ကဏ္ဍအလိုက် စက်သုံးဆီဖြန့်ဖြူးရောင်းချမှုစာရင်း",
    startDate: "စတင်ရက်",
    endDate: "ပြီးဆုံးရက်",
    station: "စတေရှင်",
    search: "ရှာဖွေသည်",
    no: 'စဉ်',
    content: "အကြောင်းအရာ",
    saleDepartment: "အရောင်းဆိုင်ကဏ္ဍ",
    fuelType: "စက်သုံးဆီအမျိုးအစား",
    count: "စီးရေ",
    liter: "လီတာ",
    remark: "မှတ်ချက်",
    cycle: "နှစ်ဘီးတပ်",
    cycle3Wheel: "သုံးဘီးတပ်",
    car: "အိမ်စီးကား",
    bus: "ခရီးသည်တင်ယာဥ်",
    city: "ဒေသတွင်း",
    highWay: "ခရီးဝေး",
    lightTruck: "ကုန်တင်ယာဥ်(ဝန်ပေါ့)",
    heavyTruck:"ကုန်တင်ယာဥ်(ဝန်လေး)",
    trailer: "ကုန်တင်ယာဥ်(တွဲကား)",
    htawlargyi: "ထရော်လာဂျီ",
    tractor: "ထွန်စက်",
    smallTractor:"လက်တွန်းထွန်စက်",
    heavyMachinery: "စက်ယန္တရားယာဥ်",
    commericalVehicle: "လုပ်ငန်းသုံးယာဥ်",
    phoneTower: "ဖုန်းတာဝါတိုင်",
    industrialZone: "စက်မှုဇုန်",
    withABucket:"ပုံးဖြင့်",
    generatorIndustry: "စက်မှုလုပ်ငန်းသုံး မီးစက်/အင်ဂျင်",
    agricultureMachine: "လယ်ယာသုံးစက်ပစ္စည်း",
    generatorHomeUse: "အိမ်သုံးမီးစက်/ရေစက်",
    hospital: "ဆေးရုံ",
    school: "စာသင်ကျောင်း",
    superMarket: "စျေး",
    hotel: "ဟိုတယ်",
    housing: "အိမ်ယာ",
    boat: "စက်လှေ",
    total: "စုစုပေါင်း",
    toExcel: "Excel သို့ပြောင်းရန်",
    toPrint:"Print ထုတ်ရန်",
    a: "က",
    b: "ခ",
    c: "ဂ",
    d: "ဃ",
    e: "င",
    f: "စ",
    g: "ဆ",
    h: "ဇ",
    i: "ဈ",
    aa:"၁",
    bb: "၂",
    cc: "၃",
    dd: "၄",
    ee: "၅",
    ff: "၆",
    gg: "၇",
    hh: "၈",
    ii: "၉",
    jj: "၁၀",
    kk: "၁၁",
    ll: "၁၂",
    mm: "၁၃",
    nn: "၁၄",
}