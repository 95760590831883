export const EnglishFuelTypeBalanceReport = {
  main_title: "Fuel Type Balance Report",
  start_date: "From Date",
  end_date: "To Date",
  station: "Station",
  search: "SEARCH",
  title: "Fuel Type Balance Report In",
  tank: "Tank",
  fuel_type: "Fuel Type",
  capacity: "Capacity",
  opening: "Opening",
  receive: "Receive",
  sale: "Sale",
  balance: "Balance",
  liter: "Liter",
};