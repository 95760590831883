export const EnglishStockBalance = {
  main_title: "Stock Balance Report",
  start_date: "From Date",
  end_date: "To Date",
  tank_no: "Tank No.",
  station: "Station",
  search: "SEARCH",
  no: "No",
  tank: "Tank",
  opening: "Opening",
  receive: "Receive",
  issue: "Issue",
  adjust: "Adjust",
  balance: "Balance",
  today_tank: "Today Tank",
  yesterday_tank: "Yesterday Tank",
  total_issue: "Total Issue",
  today_gl: "Today G/L",
  total_gl: "Total G/L",
  toExcel: "To Excel",
  toPrint: "To PDF",
  total_record: "Total Record",
};