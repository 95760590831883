export const MyanmarFuelBalanceRport = {
  main_title: "ဆီလက်ကျန်စာရင်း",
  date: "ရက်စွဲ",
  tank_no: "ဆီကန် နံပါတ်",
  station: "စတေရှင်",
  search: "ရှာဖွေသည်",
  tank: "ဆီကန်",
  fuel_type: "ဆီအမျိုးအစား",
  capacity: "ဝင်ဆံ့မှု့",
  opening: "အဖွင့်",
  receive_volume: "လက်ခံ ပမာဏ",
  liter: "လီတာ",
  pprd_no: "PPRD လိုင်စင်နံပါတ်",
  gallon: "ဂါလန်",
  sale: "အရောင်း",
  balance: "လက်ကျန်",
  toExcel: "Excel သို့ပြောင်းရန်",
  toPrint: "Print ထုတ်ရန်",
  Station_name: "ဆိုင်အမည်", //update
  Township: "မြို့နယ်",
  State: "ပြည်နယ်/တိုင်း",
  Open: "အဖွင့် စတော့ (ဂါလံ)",
  Close: "အပိတ်စတော့ (ဂါလံ)",
  Sale: "ရောင်းချမှုပမာဏ (ဂါလံ)",
  Adj: "ချိန်ညှိမှု (ဂါလံ)",
  Remark: "မှတ်ချက်",
  receive: "ဆီလက်ခံ (ဂါလံ)",
  closing_li: "Closing Liter",
  dif: "Difference Liter",
  gl: "Gain/Loss",
};