export const MyanamrCompanyTables = {
    station: "စတေရှင်",
    company_tables:"ကုမ္ပဏီ စာရင်းများ",
    start_date: "စတင်ရက်",
    end_date: "ပြီးဆုံးရက်",
    fuel_type_total: "ဆီအမျိုးအစား စုစုပေါင်း",
    no: "စဉ်",
    fuel_type: "ဆီအမျိုးအစား",
    liter: "လီတာ",
    price: 'စျေးနှုန်း',
    amount: "ပမာဏ",
    remark: 'မှတ်ချက်',
    pump_test_tabel: "ဆီပန့်အစမ်းဇယား",
    toExcel: "Excel သို့ပြောင်းရန်",
    toPrint: "Print ထုတ်ရန်",
    office_use: "ရုံးသုံး (ဆီဘောင်ဇာကား)",
    station_use: "ဆီဆိုင်သုံး ဇယား",
    station_tank_balance: "ဆီဆိုင် ဆီလက်ကျန်ဇယား",
    tank_no: "တန့်နံပါတ်",
    opening: "အဖွင့်",
    closing:"အပိတ်",
    isue: "အရောင်း",
    blance: 'လက်ကျန်',
    tank_isue: 'ဆီတန့်ရောင်း',
    today_gl: 'လစဉ် အရ၊အရှုံး',
    monthly_gl:"လစဉ် အရ၊အရှုံး",
    today_tank: "ယနေ့ လက်ကျန်",
    yesterday_tank: "မနေ့ လက်ကျန်",
    pump_meter: 'ဆီပန့် မီတာ',
    stock_balance_report: "ဆီသိုလှောင်လက်ကျန် စာရင်း",
    tank: 'ဆီတန့်',
    receive: 'ဆီအဝင်',
    issue: "အရောင်း",
    adjust: "ကိုက်ညီစေခြင်း",
    balance: "ဆီလက်ကျန်",
    total_issue: "စုစုပေါင်းအရောင်း",
    total_gl: "စုစုပေါင်း အရ/အရှုံး",
    search: "ရှာဖွေသည်",
    to_adjust: "ကိုက်ညီမှု့ ပြုလုပ်ခြင်း",
    total_record: "စုစုပေါင်း မှတ်တမ်း",
    go_back:"ပြန်လည်"
}