export const MyanmarFuelReceiveReport = {
    main_title: "ဆီဝင်လက်ခံ စာရင်း",
    start_date: "စတင်ရက်",
    end_date: "ပြီးဆုံးရက်",
    tank_no: "ဆီကန် နံပါတ်",
    fuel_type: "ဆီအမျိုးအစား",
    station: "စတေရှင်",
    search: "ရှာဖွေသည်",
    receive_date: "လက်ခံ ရက်စွဲ",
    location: "တည်နေရာ",
    fuel_in_code: "ဆီလက်ခံ နံပါတ်",
    driver: "ယာဉ်မောင်း",
    bowser_no: "ဘောင်ဆာ နံပါတ်",
    tank: "ဆီတန့်",
    tank_capacity: "တန့်ဝင်ဆန့်မှု့",
    receive_volume: "လက်ခံ Volume",
    balance: "လက်ကျန်",
    liter: "လီတာ",
    gallon: "ဂါလန်",
    total_record:"စုစုပေါင်း မှတ်တမ်း",
    toExcel: "Excel သို့ပြောင်းရန်",
    toPrint: "Print ထုတ်ရန်",
}