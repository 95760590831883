export const UserData = [
    {
        id: 1,
        year: "92",
        userGain:14850,
        userLost:823
    },
      {
        id: 2,
        year:"95",
        userGain:10000,
        userLost:34124
    },
        {
        id: 3,
        year: "97",
        userGain:12734,
        userLost:823
    },
    {
        id: 4,
        year:"HSD",
        userGain:9382,
        userLost:356
    },
    {
        id: 4,
        year:"PHSD",
        userGain:9382,
        userLost:356
    },
]